import { useRouter } from 'next/router';
import { useEffect, useState, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';
import Container from '../../elements/Container';
import Spacer from '../../elements/Spacer';
import { useAppContext } from '../../hooks/useAppContext';
import { useContentContext } from '../../hooks/useContentContext';
import { px2Rem } from '../../utils/px2Rem';
import BenefitsInfo from '@/components/BenefitsInfo';
import { IProductsWrapper } from './types';
import { ExistingCustomerNavBar, palette } from 'uibook';
import { baseUrl, defaultLinks, addTechLink } from '@/components/navbar/constants';
import AccountBanner from '@/components/navbar/AccountBanner';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import Footer from '@/components/footer';
import QuickLinks from '@/components/quicklinks';
import TrustPilotBanner from '@/components/trustpilotbanner';
import { ThemeProvider } from 'styled-components';
import { StyledNavPlaceholder } from './ProductsWrapper.styles';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';

export const ProductsWrapper = ({ children, context }: IProductsWrapper) => {
  const { hasLoggedInCustomer, eligibleForAddTech, customerIsInMobileApp } = useCustomerContext();
  const { consumerIsPersonal, consumerIsBusiness } = useConsumerTypeContext();
  const { benefitsListItems } = useContentContext();
  const { isRayloPay, theme } = useAppContext();
  const { boot, update, shutdown } = useIntercom();
  const router = useRouter();

  /**
   * Reading the `hasLoggedInCustomer` value directly from the context can cause a hydration issue
   * between the server-generated markup and the client-generated markup. We can conditionally show
   * the `ExistingCustomerNavBar` and `AccountBanner` by using a local state to store the value of
   * `hasLoggedInCustomer` and updating it when the context value changes.
   */
  const [localHasLoggedInCustomer, setLocalHasLoggedInCustomer] = useState(false);
  const [localCustomerIsInMobileApp, setLocalCustomerIsInMobileApp] =
    useState(customerIsInMobileApp);

  useEffect(() => {
    setLocalHasLoggedInCustomer(hasLoggedInCustomer);
    setLocalCustomerIsInMobileApp(customerIsInMobileApp);
  }, [hasLoggedInCustomer, customerIsInMobileApp]);

  useEffect(() => {
    if (isRayloPay) {
      boot({
        customLauncherSelector: '.launch-intercom',
      });
      if (router?.asPath?.includes('products/')) {
        update({
          alignment: 'middle',
          horizontalPadding: 32,
          verticalPadding: 150,
        });
      }
    }
  }, [isRayloPay, boot, update, router?.asPath]);

  const isProductDescriptionPage = router?.asPath.includes('products/');
  const isAboutPage = router?.asPath.includes('about');

  const links = useCallback(() => {
    if (eligibleForAddTech && context === 'product') {
      return [...defaultLinks, addTechLink];
    }

    return defaultLinks;
  }, [eligibleForAddTech, context]);

  const logout = () => {
    if (window?.Intercom) {
      shutdown();
    }
    window.location.assign(`${baseUrl}/logout`);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledNavPlaceholder $loggedIn={localHasLoggedInCustomer} />
      {localHasLoggedInCustomer && !localCustomerIsInMobileApp && (
        <>
          <ExistingCustomerNavBar
            logoLink={`${baseUrl}/account`}
            links={links()}
            onLogout={logout}
          />
          <AccountBanner navigateBack={isProductDescriptionPage} />
        </>
      )}
      <Container width="100%">{children}</Container>

      {!isProductDescriptionPage && !isAboutPage && !localCustomerIsInMobileApp && (
        <TrustPilotBanner
          /**
           * On the Business PLP, the `TrustPilotBanner` renders directly before the `<Footer />`.
           * Both have the same background colour, so we need to add a border to separate them.
           */
          enableBorderBottom={consumerIsBusiness}
          dataTestId="trust-pilot-banner"
        />
      )}
      {!isRayloPay && consumerIsPersonal && (
        <>
          <BenefitsInfo items={benefitsListItems} />
          {!localHasLoggedInCustomer && (
            <>
              <Spacer height={32} />
              <QuickLinks />
              <Spacer height={32} />
            </>
          )}
        </>
      )}
      {isRayloPay && (
        <Container backgroundColor={palette.white} padding={`0 ${px2Rem(24)}`}>
          <Spacer height={32} />
          <BenefitsInfo items={benefitsListItems} />
          <Spacer height={32} />
        </Container>
      )}
      {!localCustomerIsInMobileApp && <Footer />}
    </ThemeProvider>
  );
};
