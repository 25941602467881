import { palette } from 'uibook';
import Header from '../../elements/Header';
import Spacer from '../../elements/Spacer';
import { ILink, ILinks, useContentContext } from '../../hooks/useContentContext';
import {
  StyledLink,
  StyledQuickLinks,
  StyledQuickLinksContainer,
  StyledQuickLinksGroup,
} from './styles';
import { IQuickLinks } from './types';

export const QuickLinks = ({ dataTestId }: IQuickLinks) => {
  const { listOne, listTwo, listThree } = useContentContext();
  const { title: listOneTitle, links: listOneLinks }: ILinks = listOne;
  const { title: listTwoTitle, links: listTwoLinks }: ILinks = listTwo;
  const { title: listThreeTitle, links: listThreeLinks }: ILinks = listThree;

  return (
    <StyledQuickLinks data-testid={dataTestId}>
      <StyledQuickLinksContainer>
        <StyledQuickLinksGroup $tabletWidth="16%">
          <Header level={4} fontSize={14} color={palette.charcoal[500]}>
            Quick Links
          </Header>
        </StyledQuickLinksGroup>
        <StyledQuickLinksGroup $tabletWidth="28%">
          <Header level={4} fontSize={14} color={palette.charcoal[500]}>
            {listOneTitle}
          </Header>
          {listOneLinks?.map(({ label, link }: ILink) => (
            <div key={link}>
              <StyledLink href={link}>{label}</StyledLink>
              <Spacer height={12} />
            </div>
          ))}
        </StyledQuickLinksGroup>
        <StyledQuickLinksGroup $tabletWidth="28%">
          <Header level={4} fontSize={14} color={palette.charcoal[500]}>
            {listTwoTitle}
          </Header>
          {listTwoLinks?.map(({ label, link }: ILink) => (
            <div key={link}>
              <StyledLink href={link}>{label}</StyledLink>
              <Spacer height={12} />
            </div>
          ))}
        </StyledQuickLinksGroup>
        <StyledQuickLinksGroup $tabletWidth="28%">
          <Header level={4} fontSize={14} color={palette.charcoal[500]}>
            {listThreeTitle}
          </Header>
          {listThreeLinks?.map(({ label, link }: ILink) => (
            <div key={link}>
              <StyledLink href={link}>{label}</StyledLink>
              <Spacer height={12} />
            </div>
          ))}
        </StyledQuickLinksGroup>
      </StyledQuickLinksContainer>
    </StyledQuickLinks>
  );
};
