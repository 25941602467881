import { palette } from 'uibook';
import Container from '../../elements/Container';
import Copy from '../../elements/Copy';
import Grid from '../../elements/grid';
import Icon from '../../elements/Icon';
import Spacer from '../../elements/Spacer';
import { px2Rem } from '../../utils/px2Rem';
import { StyledBenefitsInfo, StyledBenefitsInfoItem } from './styles';
import { IBenefitsInfo } from './types';

export const BenefitsInfo = ({ dataTestId, items, backgroundColor }: IBenefitsInfo) => {
  return (
    <StyledBenefitsInfo data-testid={dataTestId} backgroundColor={backgroundColor}>
      <Container center>
        <Container maxWidth={px2Rem(1200)}>
          <Spacer height={32} />
          <Grid columns={4} columnsTablet={2} columnsMobile={1} columnGap={48} rowGap={48}>
            {items?.map(({ icon, title, copy }, i) => (
              <div key={i}>
                <StyledBenefitsInfoItem data-testid="benefits-info-item">
                  <Icon name={icon} dataTestId={`icon-${i}`} />
                  <Spacer height={16} />
                  <Copy bold dataTestId={`title-${i}`}>
                    {title}
                  </Copy>
                  <Spacer height={16} />
                  <Copy
                    color={palette.charcoal[400]}
                    fontSize={16}
                    lineHeight={24}
                    dataTestId={`copy-${i}`}
                  >
                    {copy}
                  </Copy>
                </StyledBenefitsInfoItem>
              </div>
            ))}
          </Grid>
          <Spacer height={32} />
        </Container>
      </Container>
    </StyledBenefitsInfo>
  );
};
